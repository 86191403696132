import React from 'react';
import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';

const media = {
  secTitle: 'Central Appalachia (Charleston, WV & Perry County, KY)',
  cityModal: true,
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/iGdv1EdwgaU',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Central Appalachia is a part of the country deeply rooted in its own
      history, identity and sense of place. Over the course of a week, we
      visited several cities and towns from Charleston, West Virginia through
      eastern Kentucky. Charleston’s population is slowly diversifying, with 78
      percent identifying as white. The median wages in Charleston vary
      considerably by race and ethnicity, with whites earning approximately
      $14,000 more than blacks. Cutbacks in the coal industry have impacted the
      community tremendously with 1 in 5 living in poverty. In eastern Kentucky,
      Perry County has experienced a population downturn in recent years, but
      its population of people of color has risen slightly. In Kentucky, the
      statewide median wage is just over $29,000, but in Perry County, it is
      $2,500 less. Six in 10 jobs in Kentucky require at least some college, but
      only four in 10 Perry County residents have any college education.
    </p>
    <p>
      Along the way, we held in-depth discussions with residents, conducted
      focus groups and roundtable discussions with community leaders and local
      civic organizations. We heard about the hardships and resilience of coal
      miners who are looking for ways to transition to the new economy. In West
      Virginia, we learned about a school using innovative, trauma-informed
      approaches in St. Alban’s, a drug court program in Boone County that is
      giving people a second chance, and a community health center in Williamson
      that is an anchor for revitalization. We visited the Appalshop in
      Whitesburg, Kentucky, to learn about the storytelling they’ve done for
      more than 30 years.
    </p>

    <p>
      In Charleston, WV, we held three focus groups with mixed gender black
      residents and non-college-educated white men and women. In Kentucky, we
      stopped in at the Appalshop in Whitesburg to learn about the storytelling
      they’ve done for more than 30 years. In Hazard, we held a roundtable with
      educators working to create new pathways to economic opportunity in a
      longtime mining community visited by former Senator Bobby Kennedy over 51
      years ago. In Charleston, WV, we held three focus groups with mixed gender
      black residents and non-college-educated white men and women. In Perry
      County, KY, we spoke with one focus group of non-college-educated white
      men and women. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
